<template>
  <div class="card elevation-5">
    <div class="pa-5">
      <p>Mensalidade de Maio</p>
      <div class="d-flex pt-2">
        <v-icon class="tamanho-icon">mdi-tag-outline</v-icon>
        <p class="pl-3">R$ 363,97</p>
      </div>
      <p class="color-letra pt-2">Pague até o dia 11/05/2020</p>
    </div>
  </div>

</template>

<script>
export default {
  name: "CardFinanceiro",
  props: ["title", "path"],
  methods: {
    removeFile() {
      this.$emit("clickRemove", true);

    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  border-left: 10px solid yellow;
}

.tamanho-icon {
  font-size: 20px !important;
  color: black !important;
}

.color-letra{
  color: darkgray;
}
</style>
